import React from 'react'
import Link from 'gatsby-link'

import logo from '../img/mark.png'
import wordmark from '../img/name.png'

import Headroom from 'react-headroom'

class Navbar extends React.Component {

  state = {
    showMenu: false
  }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    })
  }

  rollOff = () => {
    this.setState({showMenu: ''})
  }

  render() {
    const menuVis = this.state.showMenu
      ? 'is-active'
      : '';
    return (<header>
      <Headroom disableInlineStyles>
      <div className="upper-nav">
        <div className="upper-nav-contain">
          <p>
          <Link to="/contact" onClick={this.rollOff}>Contact</Link>
          </p>
          <p>info@jfleaders.com / (305) 692-0392</p>
        </div>
      </div>
      <nav className="navbar">

        <div className="container">
          <div className="navbar-brand">
            <Link to="/">
              <img src={logo} alt="Joint Force Leadership" className="logo"/>
              <img src={wordmark} alt="Joint Force Leadership" className="wordmark"/>
              <div className="triangle"></div>
            </Link>
            <a role="button" className={`navbar-burger burger ${menuVis}`} onClick={this.toggleMenu}>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div className={`navbar-menu ${menuVis}`}>
            <div className="navbar-end">
              <Link className="navbar-item" to="/" onClick={this.rollOff}>Home</Link>
              <Link className="navbar-item" to="/our-system" onClick={this.rollOff}>Our System</Link>
              <Link className="navbar-item" to="/about" onClick={this.rollOff}>About</Link>
              <Link className="navbar-item" to="/services" onClick={this.rollOff}>Services</Link>
              <Link className="navbar-item" to="/blog" onClick={this.rollOff}>Blog</Link>
              <Link className="navbar-item" to="/get-the-book" onClick={this.rollOff}>Get The Book</Link>
            </div>
          </div>
        </div>
      </nav>
    </Headroom>

    </header>);
  }
}

export default Navbar
