import React from 'react'
import Helmet from 'react-helmet'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ErrorBoundary from '../components/Error'
import './all.scss'

const TemplateWrapper = ({children}) => (<div>
  <Helmet title="Home | Joint Force Leadership">
  <script src="https://kit.fontawesome.com/873bf9ad23.js" crossorigin="anonymous"></script>
  </Helmet>
  <Navbar/>
  <ErrorBoundary>
  <div>{children}</div>
  </ErrorBoundary>
  < Footer/></div>)

export default TemplateWrapper
